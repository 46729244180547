<template>
  <div class="col-lg-4 mb-lg-0 align-self-end">
    <b-button
      v-if="checkPermission('create category')"
      v-b-modal.form-category-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Add User"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Kategori
    </b-button>

    <b-modal
      id="form-category-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Kategori"
    >
      <div class="form">
        <h5>Kategori</h5>
        <div class="row">
          <div class="col-lg-6">
            <label>Pilih Induk Kategori</label>
            <v-select
              v-model="formPayloadCategory.parent_uuid"
              :options="parents"
              :reduce="option => option.uuid"
              @search="onSearchParent"
            >
              <template slot="no-options">
                Ketik nama kategori
              </template>
            </v-select>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nama:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadCategory.name"
                  :state="validationsCategory.name ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsCategory.name"
                  v-if="validationsCategory.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Icon</label><br>
              <validation-provider>
                <b-form-file
                  placeholder="Pilih file atau letakkan di sini..."
                  drop-placeholder="Drop file here..."
                  :state="validationsCategory.icon ? false:null"
                  @change="changePhoto($event)"
                />
                <small
                  v-for="(validation, index) in validationsCategory.icon"
                  v-if="validationsCategory.icon"
                  :key="`errorIcon${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingCategory">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingCategory"
          @click="editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormFile,
    vSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    cleanUpForm: {
      type: Function,
    },
    parent: {
      type: Object,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      parents: [],
      isLoadingCategory: false,
      formPayloadCategory: {
        name: '',
        parent_uuid: '',
      },
      validationsCategory: '',
      categoryName: '',
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingCategory = value
    },
    validations(value) {
      this.validationsCategory = value
    },
    parent(value) {
      if (value) {
        this.parents = [{
          uuid: value.uuid,
          label: value.name,
        }]
        this.formPayloadCategory.parent_uuid = value.uuid
      }
    },
    formPayload: {
      handler(value) {
        this.formPayloadCategory.name = value.name
        if (this.editId == null) {
          this.formPayloadCategory.icon = value.icon
        }
      },
      deep: true,
    },
    formPayloadCategory: {
      handler(value) {
        this.$emit('payloadCategory', value)
      },
      deep: true,
    },
  },
  methods: {
    changePhoto(event) {
      this.formPayloadCategory.icon = event.target.files[0] ? event.target.files[0] : event
    },
    onSearchParent(search, loading) {
      if (search.length) {
        loading(true)
        this.searchParent(loading, search, this)
      }
    },
    searchParent: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/serviceCategory?keyword=${escape(search)}`)
        .then(response => {
          vm.parents = []
          response.data.data.data.forEach(parent => {
            vm.parents.push({
              uuid: parent.uuid,
              label: parent.name,
            })
          })
          loading(false)
        })
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
